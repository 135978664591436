import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
} from "react-router-dom";
import EnterLostDisc from "./EnterLostDisc";
import Inventory from "./Inventory";
import ForSaleInventory from "./ForSaleInventory";
import {
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  Divider,
  Alert,
  Snackbar,
  IconButton,
} from "@mui/material"; // Import Button and ButtonGroup from MUI
import CryptoJS from "crypto-js";
import "../globals.css";
import fullTransparentLogo1700 from "../Images/full_logo_transparent_1740x300.png";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { API_BASE_URL } from "../App";
import LoggedOut from "./LoggedOut";
import Banner from "./Banner";
import NoOrg from "./NoOrg";

export interface Course {
  orgCode: string;
  courseName: string;
}

function AdminPanel() {
  const REACT_APP_ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD;
  const [activeTab, setActiveTab] = useState("enterLostDisc"); // Default active tab
  const [isPasswordEntered, setIsPasswordEntered] = useState(false); // Track whether the password is entered
  const location = useLocation();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const [orgCode, setOrgCode] = useState<string>("");
  // const [allOrgCodes, setAllOrgCodes] = useState<KindeOrganizations>({
  //   orgCodes: [],
  // });
  const [courses, setCourses] = useState<Course[]>([]); // Array of courses for each orgCode
  const [course, setCourse] = useState<Course>({ orgCode: "", courseName: "" });
  const [bannerOpen, setBannerOpen] = useState(true); // Banner open state
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSwitchingOrgs, setIsSwitchingOrgs] = useState(false);
  const [belongsToOrg, setBelongsToOrg] = useState(false);
  const {
    login,
    register,
    logout,
    user,
    isAuthenticated,
    isLoading,
    getOrganization,
    getUserOrganizations,
    getToken,
  } = useKindeAuth();

  const clearSuccessMessage = () => {
    setShowSuccessMessage(false);
    setSuccessMessage("");
  };

  const clearErrorMessage = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
  };

  const hashPassword = (password: string) => {
    return CryptoJS.SHA256(password).toString();
  };

  const hashedAdminPassword: string = hashPassword(
    REACT_APP_ADMIN_PASSWORD!
  ).toString();

  const switchTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const fetchCourse = async (orgCodeIn: string) => {
    console.log("fetching course for orgCode", orgCodeIn);
    if (!orgCodeIn || orgCodeIn === "" || orgCodeIn === "org_6c3b341e563") {
      console.error("Organization code is required");
      setErrorMessage("Organization code is required.");
      setShowErrorMessage(true);
      setBelongsToOrg(false);
      return;
    }

    try {
      console.log(`${API_BASE_URL}/course/${orgCodeIn}`);
      // console.log(`${API_BASE_URL}/courses/${orgCodeIn}`);

      const accessToken = await getToken();

      const response = await axios.get(`${API_BASE_URL}/course/${orgCodeIn}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // const response = await axios.get(`${API_BASE_URL}/courses/${orgCodeIn}`, {
      //   headers: {
      //     Authorization: `Bearer ${accessToken}`,
      //   },
      // });
      console.log(response);
      const data = response.data;
      console.log(data);
      setCourse(data);
      setBelongsToOrg(true);
    } catch (error) {
      console.error(`Error fetching course for orgCode ${orgCodeIn}: ${error}`);
      setErrorMessage(
        `Error fetching course for orgCode ${orgCodeIn}: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
      setShowErrorMessage(true);
      setBelongsToOrg(false);
    }
  };

  const fetchCourses = async (orgCodesIn: string[]) => {
    if (orgCodesIn.length === 0) {
      console.error("No organization codes provided");
      setErrorMessage("No organization codes found. Please contact support.");
      setShowErrorMessage(true);
      return;
    }

    console.log("fetching courses for orgCodes", orgCodesIn);

    try {
      const accessToken = await getToken();
      const response = await axios.get(
        `${API_BASE_URL}/courses?orgCodes[]=${orgCodesIn.join("&orgCodes[]=")}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const fetchedCourses: Course[] = response.data;
      console.log(fetchedCourses);
      setCourses(fetchedCourses);
    } catch (error) {
      console.error(`Error fetching courses: ${error}`);
      setErrorMessage(
        `Error fetching courses: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
      setShowErrorMessage(true);
    }
  };

  const handleClosePopup = () => setShowErrorMessage(false);

  // useEffect(() => {
  //   checkCookie();
  // }, []);

  useEffect(() => {
    if (user) {
      console.log("refreshing orgs");
      const orgCodeJSON = getOrganization() as unknown as JSON;
      const orgCode = JSON.parse(JSON.stringify(orgCodeJSON)).orgCode;
      console.log(orgCode);
      setOrgCode(orgCode);
      fetchCourse(orgCode);

      const orgCodesJSON = getUserOrganizations() as unknown as JSON;
      const orgCodes = JSON.parse(JSON.stringify(orgCodesJSON)).orgCodes;
      console.log(orgCodes);
      // setAllOrgCodes(orgCodes);
      fetchCourses(orgCodes);
    }
  }, [user, isLoading, getOrganization, getUserOrganizations]);

  // Function to handle password submission
  // const handlePasswordSubmit = () => {
  //   const enteredPassword = prompt("Please enter the password:"); // Prompt for the password

  //   if (hashPassword(enteredPassword!) === hashedAdminPassword) {
  //     setIsPasswordEntered(true); // Set the flag to true if the password is correct
  //     Cookies.set("admin_auth", hashedAdminPassword, { expires: 7 }); // expires in 7 days
  //   } else {
  //     alert("Incorrect password. Please try again.");
  //   }
  // };

  // Function to check the hashed password from the cookie
  // const checkCookie = () => {
  //   const hashedPasswordInCookie = Cookies.get("admin_auth");
  //   if (hashedPasswordInCookie) {
  //     if (hashedAdminPassword === hashedPasswordInCookie!) {
  //       setIsPasswordEntered(true);
  //     } else {
  //       setIsPasswordEntered(false);
  //     }
  //   }
  // };

  const handleLogout = () => {
    // Cookies.remove("admin_auth");
    // setIsPasswordEntered(false);
    logout();
  };

  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = React.useState<number>(1);
  const [lastIndex, setLastIndex] = React.useState<number>(-1);

  const createHandleClose = (index: number) => () => {
    setLastIndex(selectedIndex);
    if (typeof index === "number") {
      setSelectedIndex(index);
    }
    if (index === 3) {
      navigate("/");
    }
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loginWithOrgCode = (orgCode: string) => async () => {
    setIsSwitchingOrgs(true);
    await login({
      app_state: {
        redirectTo: location ? location.pathname + location.search : null,
      },
      org_code: orgCode,
    });

    setIsSwitchingOrgs(false);

    // refreshOrgs();
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorMessage(false);
  };

  const refresh = () => {
    // window.location.reload();
    navigate("/");
  };

  return (
    <div className="App">
      <Banner open={bannerOpen} setOpen={setBannerOpen} />
      <header className="App-header">
        <img
          src={fullTransparentLogo1700}
          alt="DRN-Logo"
          className="logo-300"
          onClick={() => refresh()}
        />
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ position: "absolute", right: "20px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            // className="Navbar-menu-icon"
            viewBox="0 0 24 24"
            style={{
              fill: "rgba(0, 0, 0, 1)",
              margin: "auto",
            }}
          >
            <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
          </svg>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {user && (
            <MenuItem>
              Welcome, {user.given_name} {user.family_name}
            </MenuItem>
          )}

          {user && (
            <MenuItem
              sx={{ color: "var(--primary-red)" }}
              onClick={() => handleLogout()}
            >
              Logout
            </MenuItem>
          )}

          {user && <Divider />}

          {!user && (
            <MenuItem
              sx={{ color: "var(--primary-blue)" }}
              onClick={() =>
                login({
                  app_state: {
                    redirectTo: location
                      ? location.pathname + location.search
                      : null,
                  },
                })
              }
            >
              Login
            </MenuItem>
          )}

          {user && (
            <MenuItem
              {...(selectedIndex === 0 && {
                selected: true,
                variant: "soft",
              })}
              onClick={createHandleClose(0)}
            >
              Enter Lost Disc
            </MenuItem>
          )}
          {user && (
            <MenuItem
              selected={selectedIndex === 1}
              onClick={createHandleClose(1)}
            >
              Inventory
            </MenuItem>
          )}
          {user && (
            <MenuItem
              selected={selectedIndex === 2}
              onClick={createHandleClose(2)}
            >
              For Sale
            </MenuItem>
          )}
          {courses.length > 1 && (
            <>
              <Divider />
              {courses.map((courseIn, index) => (
                <>
                  {course.orgCode !== courseIn.orgCode && (
                    <MenuItem
                      key={courseIn.orgCode}
                      selected={selectedIndex === index + 3}
                      onClick={loginWithOrgCode(courseIn.orgCode)}
                    >
                      Switch to {courseIn.courseName}
                    </MenuItem>
                  )}
                </>
              ))}
              {user && <Divider />}
            </>
          )}
        </Menu>
      </header>
      {isAuthenticated ? (
        <main className="container">
          {belongsToOrg ? (
            <>
              {selectedIndex === 0 && (
                <EnterLostDisc
                  course={course}
                  setSelectedIndex={setSelectedIndex}
                  createHandleClose={() => createHandleClose}
                  lastIndex={lastIndex}
                  setLastIndex={setLastIndex}
                />
              )}
              {selectedIndex === 1 && (
                <Inventory
                  course={course}
                  setSelectedIndex={setSelectedIndex}
                  createHandleClose={() => createHandleClose}
                  lastIndex={lastIndex}
                  setLastIndex={setLastIndex}
                />
              )}
              {selectedIndex === 2 && (
                <ForSaleInventory
                  course={course}
                  setSelectedIndex={setSelectedIndex}
                  createHandleClose={() => createHandleClose}
                  lastIndex={lastIndex}
                  setLastIndex={setLastIndex}
                />
              )}
            </>
          ) : (
            <NoOrg />
          )}
        </main>
      ) : (
        <LoggedOut />
      )}
      <Snackbar
        open={showErrorMessage}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AdminPanel;
