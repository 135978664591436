// PopupComponent.tsx
import React, { useEffect, useRef, useState } from "react";
import "../styles/ImageDetectionPopup.css";
import "../globals.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { DiscMold } from "./EnterLostDisc";

interface PopupProps {
  data: Array<{ text: string; category: string }>;
  onClose: () => void;
  onUpdateCategory: (index: number, category: string) => void;
  onUpdateText: (index: number, text: string) => void;
  onDelete: (index: number) => void;
  prefillForm: () => void;
  categories: Array<string>;
  setTypedDiscName: (name: string) => void;
}
const ImageDetectionPopup: React.FC<PopupProps> = ({
  data,
  onClose,
  onUpdateCategory,
  onUpdateText,
  onDelete,
  prefillForm,
  categories,
  setTypedDiscName,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedText, setEditedText] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: number]: string }>({});
  const [isAnyCategorySelect, setIsAnyCategorySelect] =
    useState<boolean>(false);
  const textInputRef = useRef<(HTMLInputElement | null)[]>([]);
  const [showDiscPicker, setShowDiscPicker] = useState<boolean>(false);

  // Focus on the first input on load
  useEffect(() => {
    if (textInputRef.current[0]) {
      textInputRef.current[0].focus();
    }
  }, []);

  // Check for errors on re-render
  useEffect(() => {
    const newErrors: { [key: number]: string } = {};
    data.forEach((item, index) => {
      if (item.category === "") {
        newErrors[index] = "Please select a category";
      }
    });
    setErrors(newErrors);
  }, [data]);

  const startEditing = (text: string, index: number) => {
    setEditingIndex(index);
    setEditedText(text);
  };

  const saveEditing = () => {
    // Call your onUpdateCategory function with the updated text and editingIndex
    if (editingIndex !== null) {
      onUpdateText(editingIndex, editedText);
    }
    // Clear editing state
    setEditingIndex(null);
    setEditedText("");
  };

  const handleMouseDown = (event: MouseEvent) => {
    // Check if the press starts inside the popup
    if (popupRef.current && popupRef.current.contains(event.target as Node)) {
      console.log("click inside");
    } else {
      onClose();
    }
  };

  useEffect(() => {
    // Add event listener
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  useEffect(() => {
    // Check if any category is "SELECT"
    console.log(data);
    const hasSelectCategory = data.some((item) => item.category === "");
    setIsAnyCategorySelect(hasSelectCategory);
  }, [data]);

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (isAnyCategorySelect || Object.keys(errors).length > 0) {
      console.log(
        "Editing disabled because: ",
        editingIndex,
        isAnyCategorySelect,
        errors
      );
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [editingIndex, isAnyCategorySelect, errors]);

  const handleCategoryChange = (index: number, category: string) => {
    onUpdateCategory(index, category);
    if (category === "" || category === "Select Category") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: "Please select a category",
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[index];
        return newErrors;
      });
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-content" ref={popupRef}>
        <div className="popup-table-container">
          <table className="popup-table">
            <colgroup>
              <col style={{ width: "75%" }} />
              <col style={{ width: "25%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Captured Text</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      value={item.text}
                      onChange={(e) => onUpdateText(index, e.target.value)}
                      className="popup-textfield"
                      ref={(el) => (textInputRef.current[index] = el)}
                    />
                  </td>
                  <td className="category-row">
                    <select
                      value={item.category}
                      className="popup-select"
                      onChange={(e) =>
                        handleCategoryChange(index, e.target.value)
                      }
                    >
                      {categories.map((category, categoryIndex) => (
                        <option key={categoryIndex} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation(); // Stop event propagation
                        onDelete(index); // Call onDelete with the index
                      }}
                      sx={{
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "7.5px",
                        cursor: "pointer",
                        fontSize: "1rem",
                      }}
                    />
                    {errors[index] && (
                      <p className="error-text">
                        {errors[index]} or delete this row
                      </p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row-apart">
          <p className="disclaimer">
            Text is captured via our AI Integration. As the model is always
            learning, you may experience wrong categories initially selected or
            text that is not needed. You can delete these by hitting the delete
            icon or simply click on the text you wish to edit.
          </p>
          <button
            disabled={submitDisabled}
            onClick={prefillForm}
            className={
              submitDisabled ? "button-submit-disabled" : "button-submit"
            }
          >
            Submit to Form
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageDetectionPopup;
