import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, Disc, DiscStateString } from "../App";
import "../globals.css";
import "../styles/Inventory.css";
import { DateTime } from "luxon";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  faCircle,
  faSquareCaretUp,
  faSquareCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditDialog from "./EditDialog";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { SelectChangeEvent } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import BackToTopButton from "./BackToTopButton";
import PullToRefresh from "react-simple-pull-to-refresh";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import Legend, { DiscCounts } from "./Legend";
import { Course } from "./AdminPanel";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Brand, DiscData, DiscMold } from "./EnterLostDisc";

// Define a type for row IDs, assuming it's a number
type RowId = number;

export interface courseAndNavProps {
  course: Course;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  createHandleClose: () => void;
  lastIndex: number;
  setLastIndex: React.Dispatch<React.SetStateAction<number>>;
}

function Inventory(props: courseAndNavProps) {
  const [inventory, setInventory] = useState<Disc[]>([]); // Provide the type 'Disc[]'
  const [claimedInventory, setClaimedInventory] = useState<Disc[]>([]); // Provide the type 'Disc[]'
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredInventory, setFilteredInventory] = useState(inventory); // Initialize with inventory data
  const [filteredClaimedInventory, setFilteredClaimedInventory] =
    useState(claimedInventory); // Initialize with claimed inventory data
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [informationalMessage, setInformationalMessage] = useState("");
  const [showInformationalMessage, setShowInformationalMessage] =
    useState(false);
  const [claimedDisc, setClaimedDisc] = useState<number>(0); // Provide the type 'Disc | null'
  const [sortOption, setSortOption] = useState<keyof Disc>("claimBy"); // Set initial sort option
  const [claimedSortOption, setClaimedSortOption] =
    useState<keyof Disc>("dateClaimed");

  // Add type annotation for sortOption
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc"); // Set initial sort direction to DESC
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const [counts, setCounts] = useState<DiscCounts>({
    needsReview: 0,
    new: 0,
    unclaimed: 0,
  });
  // const course = process.env.REACT_APP_COURSE_NAME!;
  const { getToken } = useKindeAuth();
  const [alignment, setAlignment] = React.useState("active");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const [brandsData, setBrandsData] = useState<Brand[]>([]);
  const [dbDiscData, setDBDiscData] = useState<DiscMold[]>([]);
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false); // Dialog state
  const [newDisc, setNewDisc] = useState<DiscMold>({
    MoldID: null,
    BrandID: null,
    Category: "",
    MoldName: "",
    PlasticType: "",
  }); // To store new entry fields
  const [newBrand, setNewBrand] = useState<string>(""); // For creating a new brand
  const [isCreatingNewBrand, setIsCreatingNewBrand] = useState<boolean>(false); // New brand entry state
  const [typedDiscName, setTypedDiscName] = useState<string>(""); // To capture the typed disc name
  const [typedBrandName, setTypedBrandName] = useState<string>(""); // To capture the typed brand name
  const [discData, setDiscData] = useState<DiscData>({
    course: "",
    name: "",
    disc: null,
    phoneNumber: "",
    bin: "",
    comments: "",
    dateFound: new Date().toISOString().split("T")[0],
    color: "",
  });

  async function fetchBrandsAndDiscData() {
    try {
      const accessToken = await getToken();
      const response = await axios.get(
        `${API_BASE_URL}/discs/brands-and-names`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Brands and Disc Data:", response.data);
      setBrandsData(response.data.brands);
      setDBDiscData(response.data.discMolds);
    } catch (error) {
      console.error("Error fetching brands data:", error);
    }
  }

  useEffect(() => {
    fetchBrandsAndDiscData();
  }, []);

  // Combine disc name and brand for Autocomplete
  const formattedDiscs = dbDiscData.map((disc) => {
    const brand = brandsData.find((b) => b.BrandID === disc.BrandID);
    return {
      label: `${brand?.BrandName || "Unknown Brand"} - ${disc.MoldName} (${disc.PlasticType} Plastic)`,
      MoldID: disc.MoldID,
    };
  });

  const handleDiscChange = (event: any, value: any) => {
    const selectedDisc = dbDiscData.find(
      (disc) => disc.MoldID === value?.MoldID
    );
    console.log("Selected Disc:", selectedDisc);

    if (selectedDisc) {
      setEditedDisc({
        ...editedDisc!,
        discId: selectedDisc.MoldID!,
        MoldName: selectedDisc.MoldName,
        BrandName:
          brandsData.find((b) => b.BrandID === selectedDisc.BrandID)
            ?.BrandName || "Unknown Brand",
        BrandID: selectedDisc.BrandID!,
        PlasticType: selectedDisc.PlasticType || "",
      });
      setDiscData({ ...discData, disc: selectedDisc });
      setTypedDiscName(""); // Reset the typed disc name after selection
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setTypedDiscName(newInputValue); // Capture the typed disc name
  };

  const handleBrandInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setTypedBrandName(newInputValue); // Capture the typed brand name
  };

  const handleCreateNewEntry = () => {
    setNewDisc({ ...newDisc, MoldName: typedDiscName }); // Prefill the disc name with the typed input
    setShowDialog(true); // Show dialog for new entry
  };

  const handleCreateNewBrand = () => {
    setNewBrand(typedBrandName); // Prefill the brand name with the typed input
    setIsCreatingNewBrand(true); // Show the new brand creation form
  };

  const handleNewDiscChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewDisc({ ...newDisc, [event.target.name]: event.target.value });
  };

  const handleSaveNewDisc = async () => {
    try {
      const formData = new FormData();
      if (newDisc.BrandID !== null) {
        formData.append("BrandID", newDisc.BrandID.toString());
      }
      formData.append("Category", newDisc.Category);
      formData.append("MoldName", newDisc.MoldName);
      formData.append("PlasticType", newDisc.PlasticType);

      // Send the form data using axios
      const response = await axios.post(`${API_BASE_URL}/disc`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("New Disc Created:", response.data);
      // Save the new disc entry and close the dialog
      setShowDialog(false); // Close the dialog after saving
      const newDiscTmp = {
        MoldID: response.data.MoldID,
        BrandID: response.data.BrandID,
        Category: response.data.Category,
        MoldName: response.data.MoldName,
        PlasticType: response.data.PlasticType,
      };
      setNewDisc(newDiscTmp);
      setDBDiscData((prevDiscs) => [...prevDiscs, newDiscTmp]); // Add the new disc to the existing list
      setDiscData({ ...discData, disc: newDiscTmp }); // Update the disc data with the new disc
      setEditedDisc({
        ...editedDisc!,
        discId: newDiscTmp.MoldID!,
        MoldName: newDiscTmp.MoldName,
        BrandName:
          brandsData.find((b) => b.BrandID === newDiscTmp.BrandID)?.BrandName ||
          "Unknown Brand",
        BrandID: newDiscTmp.BrandID!,
        PlasticType: newDiscTmp.PlasticType || "",
      });
      setIsCreatingNewBrand(false); // Reset the new brand state
      setSuccessMessage("New disc created successfully");
      setShowSuccessMessage(true);
    } catch (error) {
      console.error("Error creating new disc:", error);
      setErrorMessage("Error creating new disc");
      setShowErrorMessage(true);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Close dialog without saving
    setIsCreatingNewBrand(false); // Reset the new brand state
  };

  const handleNewBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewBrand(event.target.value); // Update the new brand name
  };

  const handleSaveNewBrand = async () => {
    try {
      const formData = new FormData();
      formData.append("BrandName", newBrand);

      // Send the form data using axios to create the brand
      const response = await axios.post(`${API_BASE_URL}/brand`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("New Brand Created:", response.data);

      // Create a new brand entry object
      const newBrandEntry = {
        BrandID: response.data.BrandID,
        BrandName: response.data.BrandName,
      };

      // Add the new brand to the existing list of brands
      setBrandsData((prevBrands) => [...prevBrands, newBrandEntry]);

      // Automatically select the newly created brand for the disc being created
      setNewDisc({ ...newDisc, BrandID: newBrandEntry.BrandID });

      // Hide the "Create New Brand" form after the brand is created
      setIsCreatingNewBrand(false);
      setSuccessMessage("New brand created successfully");
      setShowSuccessMessage(true);
    } catch (error) {
      console.error("Error creating new brand:", error);
      setErrorMessage("Error creating new brand");
      setShowErrorMessage(true);
    }
  };

  const [editedDiscID, setEditedDiscID] = useState<number>(-1);
  const [editedDisc, setEditedDisc] = useState<Disc | null>(null);

  console.log("Edited Disc:", editedDisc);
  console.log("Edited Disc ID:", editedDiscID);

  const startEditing = (disc: Disc) => {
    // Create a deep copy of the disc to edit
    const discCopy = JSON.parse(JSON.stringify(disc));
    setEditedDisc(discCopy);
    setEditedDiscID(disc.id!);
  };

  const stopEditing = () => {
    saveEditedDisc(editedDisc!);
    setDiscData({ ...discData, disc: null });
  };

  const saveEditedDisc = async (editedDiscIn: Disc) => {
    // Find the original disc from the inventory using the edited disc's ID
    const originalDisc = inventory.find((disc) => disc.id === editedDiscIn.id);

    console.log("Original disc:", originalDisc);
    console.log("Edited disc:", editedDiscIn);

    // Function to compare the original and edited discs
    const hasChanges = (original: Disc | undefined, edited: Disc) => {
      if (!original) return true; // If for some reason the original disc isn't found, assume changes
      // Compare each field that could be edited. Add more fields as necessary.
      return (
        original.name !== edited.name ||
        original.color !== edited.color ||
        original.phoneNumber !== edited.phoneNumber ||
        original.BrandName !== edited.BrandName ||
        original.MoldName !== edited.MoldName ||
        original.bin !== edited.bin ||
        original.comments !== edited.comments ||
        original.dateFound !== edited.dateFound ||
        original.dateTexted !== edited.dateTexted ||
        original.dateClaimed !== edited.dateClaimed ||
        original.claimBy !== edited.claimBy ||
        original.status !== edited.status ||
        original.course !== edited.course
      );
    };

    const accessToken = await getToken();
    // Proceed only if changes were detected
    if (hasChanges(originalDisc, editedDiscIn)) {
      axios
        .put(`${API_BASE_URL}/edit-disc/${editedDiscIn.id}`, editedDiscIn, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response: any) => {
          console.log("Disc updated:", response.data);
          setSuccessMessage("Disc updated successfully");
          setShowSuccessMessage(true);
          getInventory(course.courseName);
        })
        .catch((error: any) => {
          console.error("Error updating disc:", error);
          setErrorMessage(
            "Error updating disc: " + error.response.data.message
          );
          setShowErrorMessage(true);
        });
    } else {
      console.log("No changes detected, skipping update.");
      setInformationalMessage("No changes detected, skipping update.");
      setShowInformationalMessage(true);
    }
    setEditedDisc(null);
    setEditedDiscID(-1);
  };

  const {
    course,
    setSelectedIndex,
    createHandleClose,
    lastIndex,
    setLastIndex,
  } = props;

  const handleRefresh = async () => {
    getInventory(course.courseName);
  };

  const clearSuccessMessage = () => {
    setShowSuccessMessage(false);
    setSuccessMessage("");
  };

  const clearErrorMessage = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
  };

  const clearInformationalMessage = () => {
    setShowInformationalMessage(false);
    setInformationalMessage("");
  };

  // const [expandedRows, setExpandedRows] = useState<RowId[]>([]);

  // const toggleRow = (rowId: RowId) => {
  //   if (expandedRows.includes(rowId)) {
  //     setExpandedRows(expandedRows.filter((id) => id !== rowId));
  //   } else {
  //     setExpandedRows([...expandedRows, rowId]);
  //   }
  // };

  const [expandedRow, setExpandedRow] = useState<RowId | null>(null);

  const toggleRow = (rowId: RowId) => {
    // If the clicked row is already expanded, collapse it
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      // Otherwise, expand the clicked row and collapse the previous one
      setExpandedRow(rowId);
    }
  };

  // const convertToEST = (utcTimestamp: string) => {
  //   const dateUTC = DateTime.fromISO(utcTimestamp, { zone: "utc" });
  //   // const dateEST = dateUTC.setZone('America/New_York');

  //   // Format the date to display only the date (without time)
  //   //return dateEST.toFormat('yyyy-MM-dd');
  //   return dateUTC.toFormat("yyyy-MM-dd");
  // };

  const convertToEST = (httpTimestamp: string) => {
    const dateUTC = DateTime.fromHTTP(httpTimestamp, { zone: "utc" });

    // Format the date to display only the date (without time)
    return dateUTC.toFormat("yyyy-MM-dd");
  };

  const [anchorElPopover, setAnchorElPopover] =
    useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("filter button clicked");
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? "simple-popover" : undefined;
  const [isNewFilter, setIsNewFilter] = useState(false);
  const [isUnclaimedFilter, setIsUnclaimedFilter] = useState(false);
  const [isOverdueFilter, setIsOverdueFilter] = useState(false);

  const setFilter = (filterIn: string) => {
    switch (filterIn) {
      case "New":
        setIsNewFilter(!isNewFilter);
        setIsUnclaimedFilter(false);
        setIsOverdueFilter(false);
        break;
      case "Unclaimed":
        setIsNewFilter(false);
        setIsUnclaimedFilter(!isUnclaimedFilter);
        setIsOverdueFilter(false);
        break;
      case "Overdue":
        setIsNewFilter(false);
        setIsUnclaimedFilter(false);
        setIsOverdueFilter(!isOverdueFilter);
        break;
      default:
        setIsNewFilter(false);
        setIsUnclaimedFilter(false);
        setIsOverdueFilter(false);
        break;
    }
  };

  const getInventory = async (course: string) => {
    setIsLoading(true);
    const accessToken = await getToken();

    axios
      .get(`${API_BASE_URL}/inventory`, {
        params: {
          course: course,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response: any) => {
        // Convert UTC timestamps to EST

        console.log("Response:", response.data);
        const convertedInventory = response.data.map((disc: Disc) => ({
          ...disc,
          dateFound: convertToEST(disc.dateFound),
          dateTexted: disc.dateTexted ? convertToEST(disc.dateTexted) : null,
          dateClaimed: disc.dateClaimed ? convertToEST(disc.dateClaimed) : null,
          claimBy: disc.claimBy ? convertToEST(disc.claimBy) : null,
        }));
        console.log("Inventory:", convertedInventory);

        setInventory(convertedInventory);

        // Initialize counts
        let needsReviewCount = 0;
        let newCount = 0;
        let unclaimedCount = 0;

        convertedInventory.forEach((disc: Disc) => {
          if (disc.dateTexted === null) {
            newCount += 1;
          } else if (new Date(disc.claimBy!) < new Date()) {
            needsReviewCount += 1;
          } else if (
            disc.status === DiscStateString.Unclaimed &&
            new Date(disc.claimBy!) >= new Date()
          ) {
            // Assuming "unclaimed" is a valid status value
            unclaimedCount += 1;
          }
        });

        // Update state with the new counts
        setCounts({
          needsReview: needsReviewCount,
          new: newCount,
          unclaimed: unclaimedCount,
        });

        const sortedInventory = [...convertedInventory].sort(
          (a: Disc, b: Disc) => {
            const aValue = a[sortOption as keyof Disc] as string; // Cast to string
            const bValue = b[sortOption as keyof Disc] as string; // Cast to string

            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          }
        );

        // setFilteredInventory(filtered);
        const filteredInventory = sortedInventory.filter((disc: Disc) => {
          const isMatch =
            (disc.phoneNumber && disc.phoneNumber.includes(searchQuery)) ||
            disc.MoldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            disc.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            disc.BrandName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            disc.comments?.toLowerCase().includes(searchQuery.toLowerCase());

          // Check for New status
          if (isNewFilter) {
            return isMatch && disc.dateTexted === null;
          }

          // Check for Overdue
          if (isOverdueFilter) {
            return isMatch && new Date(disc.claimBy!) < new Date();
          }

          // Check for Unclaimed
          if (isUnclaimedFilter) {
            const isNotNew = disc.status !== null;
            const isNotOverdue = new Date(disc.claimBy!) >= new Date();
            return isMatch && isNotNew && isNotOverdue;
          }

          // Default return if no filter is applied
          return isMatch;
        });

        setFilteredInventory(filteredInventory);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error("Error fetching inventory:", error);
        setIsLoading(false);
      });
  };

  const getClaimedInventory = async (course: string) => {
    setIsLoading(true);
    const accessToken = await getToken();

    axios
      .get(`${API_BASE_URL}/claimed-inventory`, {
        params: {
          course: course,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response: any) => {
        // Convert UTC timestamps to EST

        console.log("Claimed Response:", response.data);
        const convertedInventory = response.data.map((disc: Disc) => ({
          ...disc,
          dateFound: convertToEST(disc.dateFound),
          dateTexted: disc.dateTexted ? convertToEST(disc.dateTexted) : null,
          dateClaimed: disc.dateClaimed ? convertToEST(disc.dateClaimed) : null,
          claimBy: disc.claimBy ? convertToEST(disc.claimBy) : null,
        }));
        console.log("Claimed Inventory:", convertedInventory);

        setClaimedInventory(convertedInventory);

        const sortedClaimedInventory = [...convertedInventory].sort(
          (a: Disc, b: Disc) => {
            const aValue = (a[sortOption as keyof Disc] || "") as string; // Provide default value
            const bValue = (b[sortOption as keyof Disc] || "") as string; // Provide default value

            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          }
        );

        // setFilteredInventory(filtered);
        const filteredInventory = sortedClaimedInventory.filter(
          (disc: Disc) => {
            const isMatch =
              (disc.phoneNumber && disc.phoneNumber.includes(searchQuery)) ||
              disc.MoldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
              disc.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              disc.BrandName.toLowerCase().includes(
                searchQuery.toLowerCase()
              ) ||
              disc.comments?.toLowerCase().includes(searchQuery.toLowerCase());

            // Default return if no filter is applied
            return isMatch;
          }
        );

        setFilteredClaimedInventory(filteredInventory);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error("Error fetching claimed inventory:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getInventory(course.courseName);
    getClaimedInventory(course.courseName);
  }, [
    course,
    searchQuery,
    sortDirection,
    sortOption,
    isNewFilter,
    isOverdueFilter,
    isUnclaimedFilter,
  ]);

  const markAsClaimed = async (discId: string) => {
    setIsLoading(true); // Set loading state to true
    const accessToken = await getToken();
    axios
      .put(`${API_BASE_URL}/mark-claimed/${discId}`, null, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        //console.log('Disc marked as claimed:', response.data);
        setIsLoading(false); // Set loading state to false
        setSuccessMessage("Disc claimed successfully"); // Set success message
        setShowSuccessMessage(true); // Show success message
        getInventory(course.courseName);
        setClaimedDisc(parseInt(discId)); // Set claimedDisc to the ID of the disc being marked as claimed
      })
      .catch((error: any) => {
        console.error("Error marking disc as claimed:", error);
        setIsLoading(false); // Set loading state to false in case of an error
        setErrorMessage("Error marking disc as claimed"); // Set error message
        setShowErrorMessage(true); // Show error message
      });
  };

  const listForSale = async (discId: string, course: string) => {
    setIsLoading(true);
    console.log("Marking as for sale");
    const accessToken = await getToken();
    // Make an API call to mark the disc as for sale
    axios
      .put(
        `${API_BASE_URL}/change-status/${discId}`,
        {
          course: course,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      // .put(`${API_BASE_URL}/change-status/${discId}`, { course: course })
      .then((response) => {
        setIsLoading(false);
        setSuccessMessage("Disc is now for sale");
        setShowSuccessMessage(true);
        setClaimedDisc(parseInt(discId));
        getInventory(course);
      })
      .catch((error) => {
        console.error("Error marking disc as for sale", error);
        setIsLoading(false);
        setErrorMessage("Error marking disc as for sale");
        setShowErrorMessage(true);
      });
  };

  // const formatPhoneNumber = (phoneNumber: string) => {
  //   // Assuming phoneNumber is in the format "1234567890"
  //   return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  // };

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = "+1 ";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumberString;
  }

  const handleSort = (selectedOption: keyof Disc) => {
    if (selectedOption === sortOption) {
      // Toggle sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortDirection("asc"); // Default to ascending if a new option is selected
    }
    setSortOption(selectedOption);
  };

  // Define a function to render the header with sorting indicator
  const renderColumnHeader = (column: keyof Disc, label: string) => {
    const isSorted = column === sortOption;
    const isAscending = sortDirection === "asc";
    const arrow = isSorted ? (isAscending ? "▲" : "▼") : null;

    return (
      <th className="table-header" onClick={() => handleSort(column)}>
        {label} {arrow}
      </th>
    );
  };

  // Define a function to render the header with sorting indicator
  const renderClaimedColumnHeader = (column: keyof Disc, label: string) => {
    const isSorted = column === claimedSortOption;
    const isAscending = sortDirection === "asc";
    const arrow = isSorted ? (isAscending ? "▲" : "▼") : null;

    return (
      <th className="table-header" onClick={() => handleSort(column)}>
        {label} {arrow}
      </th>
    );
  };

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const handleDeleteClick = () => {
    console.log("Delete clicked");
    setShowDeletePopup(true);
  };

  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const goToReportLostDisc = () => {
    setSelectedIndex(0);
    setLastIndex(1);
    createHandleClose();
  };

  const handleConfirmDelete = async (disc: Disc) => {
    setIsLoading(true);
    console.log("Deleting disc:", disc);
    const accessToken = await getToken();
    axios
      .delete(`${API_BASE_URL}/delete-disc/${disc.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Disc deleted:", response.data);
        setSuccessMessage("Disc deleted successfully");
        setShowSuccessMessage(true);
        getInventory(course.courseName);
        setIsLoading(false);
        handleClose(); // Close the popup after successful deletion
      })
      .catch((error) => {
        console.error("Error deleting disc:", error);
        console.log("Error deleting disc:", error.response.data.message);
        setErrorMessage("Error deleting Disc");
        setShowErrorMessage(true);
        setIsLoading(false);
        handleClose(); // Close the popup after an error
      });
  };

  const [lastIndexPageName, setLastIndexPageName] = useState<string>("");

  useEffect(() => {
    if (lastIndex === 0) {
      setLastIndexPageName("Enter Lost Disc");
    } else if (lastIndex === 1) {
      setLastIndexPageName("Inventory");
    } else if (lastIndex === 2) {
      setLastIndexPageName("For Sale");
    } else {
      setLastIndexPageName("Error");
    }
  }, [lastIndex]);

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {lastIndex !== -1 && (
        <div className="row-back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="back-icon"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
          </svg>

          <p
            className="lastIndexPageName"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            {lastIndexPageName}
          </p>
        </div>
      )}
      <h1 className="header">{course.courseName} L & F</h1>
      <PullToRefresh className="ptr-override" onRefresh={handleRefresh}>
        <div className="page-container">
          <div className="col-center">
            <div className="inventory-count">
              Total Discs: {filteredInventory.length}
            </div>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                width: "100%",
                maxWidth: isMobile ? "85%" : "90%",
              }}
            >
              {alignment === "active" && (
                <IconButton
                  sx={{ p: "10px" }}
                  aria-label="menu"
                  onClick={handleClickPopover}
                >
                  <FilterListOutlinedIcon />
                </IconButton>
              )}
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <InputBase
                sx={{ ml: 1, flex: 1, fontSize: "12px" }}
                placeholder="Search by id, name, disc brand, number & more"
                onChange={(e) => {
                  const inputQuery = e.target.value;

                  // Check if the input matches a phone number pattern (e.g., XXXX-XXXX-1234)
                  const isPhoneNumber = /^\d{4}-\d{4}-\d{4}$/.test(inputQuery);

                  // If it's a phone number, use the last 4 digits; otherwise, use the entire query
                  const filteredQuery = isPhoneNumber
                    ? inputQuery.slice(-4)
                    : inputQuery;

                  setSearchQuery(filteredQuery);
                }}
                value={searchQuery}
                type="text"
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>

            <Popover
              id={idPopover}
              open={openPopover}
              anchorEl={anchorElPopover}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box className={isMobile ? "filter-row-mobile" : "filter-row"}>
                <Chip
                  variant="outlined"
                  label="New"
                  className={
                    isNewFilter ? "filter-button-selected" : "filter-button"
                  }
                  avatar={
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{
                        color: "orange",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                  onClick={() => setFilter("New")}
                />
                <Chip
                  variant="outlined"
                  label="Unclaimed"
                  className={
                    isUnclaimedFilter
                      ? "filter-button-selected"
                      : "filter-button"
                  }
                  avatar={
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{
                        color: "yellow",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                  onClick={() => setFilter("Unclaimed")}
                />
                <Chip
                  variant="outlined"
                  label="Needs Review"
                  className={
                    isOverdueFilter ? "filter-button-selected" : "filter-button"
                  }
                  avatar={
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "red", width: "20px", height: "20px" }}
                    />
                  }
                  onClick={() => setFilter("Overdue")}
                />
              </Box>
            </Popover>
            <Box className="row-between">
              {alignment === "active" ? (
                <Legend counts={counts} />
              ) : (
                <p>Total claimed discs: {claimedInventory.length}</p>
              )}
              <Button
                variant="contained"
                onClick={goToReportLostDisc}
                className="small-blue-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "rgba(255, 255, 255, 1)",
                    marginRight: "5px",
                    transform: "",
                    msFilter: "",
                  }}
                >
                  <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                </svg>
                Enter Lost Disc{" "}
              </Button>
            </Box>
          </div>
          <div className="container">
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Inventory View"
              style={{ marginBottom: "10px" }}
            >
              <ToggleButton value="active">Active</ToggleButton>
              <ToggleButton value="claimed">Claimed</ToggleButton>
            </ToggleButtonGroup>
            {alignment === "claimed" ? (
              <table
                className="inventory-table"
                style={{ tableLayout: "fixed" }}
              >
                <colgroup>
                  <col style={{ width: "30px" }} />
                  <col style={{ width: "33%" }} />
                  <col style={{ width: "33%", minWidth: "150px" }} />
                  <col style={{ width: "33%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th className="table-header" />
                    {renderClaimedColumnHeader("name", "Name")}
                    {renderClaimedColumnHeader("dateClaimed", "Date Claimed")}
                    {renderClaimedColumnHeader("MoldName", "Disc")}
                  </tr>
                </thead>
                <tbody>
                  {filteredClaimedInventory.map((disc) => (
                    <React.Fragment key={disc.id}>
                      <tr onClick={() => toggleRow(disc.id!)}>
                        <td className="table-cell">
                          {expandedRow === disc.id ? (
                            <FontAwesomeIcon
                              icon={faSquareCaretUp}
                              className="icon"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSquareCaretDown}
                              className="icon"
                            />
                          )}
                        </td>
                        <td className="table-cell">
                          {disc.name.length > 0 ? disc.name : "No Name"}
                        </td>
                        <td
                          className="table-cell"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                            flexDirection: "row",
                          }}
                        >
                          {disc.claimBy}
                        </td>
                        <td className="table-cell">{disc.MoldName}</td>
                      </tr>
                      {expandedRow === disc.id && (
                        <tr>
                          <td colSpan={8}>
                            <div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>ID:</strong> {disc.id}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Course: </strong>
                                  {disc.course}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Name: </strong>
                                  {disc.name.length > 0 ? disc.name : "No Name"}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Phone Number: </strong>
                                  {disc.phoneNumber
                                    ? formatPhoneNumber(disc.phoneNumber)
                                    : "No Phone Number"}
                                </p>
                              </div>
                              <div
                                className="row "
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                              >
                                <p className="detailed-text">
                                  <strong>Disc: </strong>
                                  {disc.BrandName} {disc.MoldName} (
                                  {disc.PlasticType} Plastic)
                                </p>
                              </div>

                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Color: </strong>
                                  {disc.color}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Bin: </strong>
                                  {disc.bin}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Date Found: </strong>
                                  {disc.dateFound}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Date Texted: </strong>
                                  {disc.dateTexted}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Date Claimed: </strong>
                                  {disc.dateClaimed}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Status: </strong>
                                  {disc.status}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Claim By: </strong>
                                  {disc.claimBy}
                                </p>
                              </div>
                              <div className="row">
                                <p className="detailed-text">
                                  <strong>Comments: </strong>
                                  {disc.comments}
                                </p>
                              </div>
                              {isLoading ? (
                                <div>
                                  <CircularProgress />
                                </div>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {disc.id !== claimedDisc && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <button
                                        className="delete-button"
                                        onClick={() => handleDeleteClick()}
                                      >
                                        <RemoveCircleOutlineOutlinedIcon
                                          sx={{
                                            fontSize: ".75rem",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <p>Delete Disc</p>
                                      </button>
                                    </Box>
                                  )}
                                </Box>
                              )}

                              {showDeletePopup && (
                                <DeleteConfirmationPopup
                                  disc={disc}
                                  open={showDeletePopup}
                                  onClose={handleClose}
                                  onConfirm={() => handleConfirmDelete(disc)}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="table-container">
                <table
                  className="inventory-table"
                  style={{ tableLayout: "fixed" }}
                >
                  <colgroup>
                    <col style={{ width: "30px" }} />
                    <col style={{ width: "33%" }} />
                    <col style={{ width: "33%", minWidth: "150px" }} />
                    <col style={{ width: "33%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="table-header" />
                      {renderColumnHeader("name", "Name")}
                      {renderColumnHeader("claimBy", "Claim By")}
                      {renderColumnHeader("MoldName", "Disc")}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInventory.map((disc) => (
                      <React.Fragment key={disc.id}>
                        <tr onClick={() => toggleRow(disc.id!)}>
                          <td className="table-cell">
                            {expandedRow === disc.id ? (
                              <FontAwesomeIcon
                                icon={faSquareCaretUp}
                                className="icon"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faSquareCaretDown}
                                className="icon"
                              />
                            )}
                          </td>
                          <td className="table-cell">
                            {disc.name.length > 0 ? disc.name : "No Name"}
                          </td>
                          <td
                            className="table-cell"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              width: "100%",
                              flexDirection: "row",
                            }}
                          >
                            {disc.claimBy}
                            {new Date(disc.claimBy!) < new Date() && (
                              <Tooltip
                                title="Needs Review"
                                style={{
                                  margin: "unset !important",
                                  padding: "unset !important",
                                  minHeight: "unset !important",
                                  minWidth: "unset !important",
                                }}
                              >
                                <IconButton
                                  style={{
                                    margin: "unset !important",
                                    padding: "unset !important",
                                    minHeight: "unset !important",
                                    minWidth: "unset !important",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    style={{
                                      color: "red",
                                      width: "10px",
                                      height: "10px",
                                      margin: "auto",
                                      padding: "0px",
                                      filter: "drop-shadow(0px 0px 1px #000)",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {disc.dateTexted === null && (
                              <Tooltip title="New">
                                <IconButton>
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    style={{
                                      color: "orange",
                                      width: "10px",
                                      height: "10px",
                                      filter: "drop-shadow(0px 0px 1px #000)",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {disc.dateTexted !== null &&
                              new Date(disc.claimBy!) >= new Date() && (
                                <Tooltip title="Unclaimed">
                                  <IconButton>
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      style={{
                                        color: "yellow",
                                        width: "10px",
                                        height: "10px",
                                        filter: "drop-shadow(0px 0px 1px #000)",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </td>
                          <td className="table-cell">{disc.MoldName}</td>
                        </tr>
                        {expandedRow === disc.id && (
                          <tr>
                            <td colSpan={8}>
                              <div>
                                <div className="row-right">
                                  {editedDiscID === disc.id ? (
                                    <SaveOutlinedIcon
                                      sx={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                      onClick={stopEditing}
                                    ></SaveOutlinedIcon>
                                  ) : (
                                    <EditOutlinedIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => startEditing(disc)}
                                    ></EditOutlinedIcon>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="ID (read-only)"
                                      defaultValue={disc.id}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>ID:</strong> {disc.id}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Course (read-only)"
                                      defaultValue={disc.course}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Course: </strong>
                                      {disc.course}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Name"
                                      defaultValue={disc.name}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // Ensure we're updating the editedDisc object, not the original disc
                                        if (editedDisc) {
                                          setEditedDisc({
                                            ...editedDisc, // Spread the current state of editedDisc
                                            name: e.target.value, // Update the name with the new value
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Name: </strong>
                                      {disc.name.length > 0
                                        ? disc.name
                                        : "No Name"}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Phone Number"
                                      defaultValue={
                                        disc.phoneNumber
                                          ? formatPhoneNumber(disc.phoneNumber)
                                          : "No Phone Number"
                                      }
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // Ensure we're updating the editedDisc object, not the original disc
                                        if (editedDisc) {
                                          setEditedDisc({
                                            ...editedDisc, // Spread the current state of editedDisc
                                            phoneNumber: e.target.value, // Update the phoneNumber with the new value
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Phone Number: </strong>
                                      {disc.phoneNumber
                                        ? formatPhoneNumber(disc.phoneNumber)
                                        : "No Phone Number"}
                                    </p>
                                  )}
                                </div>
                                {/* <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Disc Brand"
                                    defaultValue={disc.BrandName}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          BrandName: e.target.value, // Update the brand with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Brand: </strong>
                                    {disc.BrandName}
                                  </p>
                                )}
                              </div> */}
                                <div
                                  className="row "
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "start",
                                  }}
                                >
                                  {editedDiscID !== disc.id && (
                                    <p className="detailed-text">
                                      <strong>Disc: </strong>
                                      {disc.BrandName} {disc.MoldName} (
                                      {disc.PlasticType} Plastic)
                                    </p>
                                  )}
                                  {editedDiscID === disc.id && (
                                    <div
                                      className="row"
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p className="">
                                        <strong>Disc: </strong>
                                        {disc.BrandName} {disc.MoldName} (
                                        {disc.PlasticType} Plastic)
                                      </p>
                                      {/* <Autocomplete
                                        options={formattedDiscs}
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        className="input-field"
                                        sx={{
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "50%",
                                        }}
                                        // Set the inputValue based on the user's typing
                                        inputValue={typedDiscName}
                                        // Set the value only when a disc is selected, not during typing
                                        value={
                                          dbDiscData
                                            .map((disc) => ({
                                              label: `${brandsData.find((b) => b.BrandID === disc.BrandID)?.BrandName || "Unknown Brand"} - ${disc.MoldName} (${disc.PlasticType} Plastic)`,
                                              MoldID: disc.MoldID,
                                            }))
                                            .find(
                                              (disc) =>
                                                disc.MoldID ===
                                                discData?.disc?.MoldID
                                            ) || null
                                        }
                                        // Handle changes when the user types
                                        onInputChange={handleInputChange}
                                        // Handle changes when the user selects a disc
                                        onChange={handleDiscChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Select a new disc"
                                            variant="outlined"
                                            helperText="Type to search or create a new entry"
                                          />
                                        )}
                                        renderOption={(props, option) => (
                                          <li {...props} key={option.MoldID}>
                                            {option.label}
                                          </li>
                                        )}
                                        fullWidth
                                        disablePortal
                                        noOptionsText={
                                          <div>
                                            <Button
                                              onClick={handleCreateNewEntry}
                                              color="primary"
                                            >
                                              Create a New Entry
                                            </Button>
                                          </div>
                                        }
                                      /> */}
                                      <Autocomplete
                                        options={[
                                          ...formattedDiscs,
                                          {
                                            label: `Create "${typedDiscName}" as a New Entry`,
                                            MoldID: null,
                                          },
                                        ]} // Dynamically add the "Create New Entry" option
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        className="input-field"
                                        style={{
                                          border: "none",
                                          textAlign: "left",
                                          backgroundColor:
                                            "var(--primary-gray)",
                                        }}
                                        value={
                                          dbDiscData
                                            .map((disc) => ({
                                              label: `${brandsData.find((b) => b.BrandID === disc.BrandID)?.BrandName || "Unknown Brand"} - ${disc.MoldName}`,
                                              MoldID: disc.MoldID,
                                            }))
                                            .find(
                                              (disc) =>
                                                disc.MoldID ===
                                                discData?.disc?.MoldID
                                            ) || null
                                        }
                                        onChange={(event, newValue) => {
                                          if (newValue?.MoldID === null) {
                                            handleCreateNewEntry(); // Call your create function when the "Create a New Entry" option is selected
                                          } else {
                                            handleDiscChange(event, newValue);
                                          }
                                        }}
                                        inputValue={typedDiscName}
                                        onInputChange={handleInputChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Select Disc"
                                            variant="outlined"
                                            helperText="Type to search or create a new entry"
                                            InputProps={{
                                              ...params.InputProps,
                                              style: {
                                                backgroundColor:
                                                  "var(--primary-white)", // Change the background color of the input field
                                              },
                                            }}
                                          />
                                        )}
                                        renderOption={(
                                          props,
                                          option,
                                          { index }
                                        ) => (
                                          <>
                                            <li
                                              {...props}
                                              key={option.MoldID || "new-entry"}
                                              style={{
                                                padding: "10px",
                                                backgroundColor:
                                                  index % 2 === 0
                                                    ? "#f9f9f9"
                                                    : "#ffffff", // Alternate background colors
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "start",
                                                textAlign: "left",
                                                fontWeight:
                                                  option.MoldID === null
                                                    ? "bold"
                                                    : "normal", // Bold for "Create a New Entry"
                                                color:
                                                  option.MoldID === null
                                                    ? "#1976d2"
                                                    : "#000",
                                              }}
                                            >
                                              {option.label}
                                            </li>
                                            {index < formattedDiscs.length && (
                                              <Divider />
                                            )}{" "}
                                            {/* Add Divider between items, but not after the last item */}
                                          </>
                                        )}
                                        fullWidth
                                        disablePortal
                                        noOptionsText={
                                          <div>
                                            <button
                                              onClick={handleCreateNewEntry}
                                              color="primary"
                                            >
                                              Create a New Entry
                                            </button>
                                          </div>
                                        }
                                      />
                                    </div>
                                  )}

                                  {/* Dialog for creating a new disc entry */}
                                  {/* Dialog for creating a new disc entry */}
                                  <Dialog
                                    open={showDialog}
                                    onClose={handleCloseDialog}
                                  >
                                    <DialogTitle>
                                      Create a New Disc Entry
                                    </DialogTitle>
                                    <DialogContent>
                                      {/* New Disc Name */}
                                      <TextField
                                        label="Disc Name"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name="MoldName"
                                        value={newDisc.MoldName}
                                        onChange={handleNewDiscChange}
                                      />

                                      {/* Plastic Type */}
                                      <TextField
                                        label="Plastic Type"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name="PlasticType"
                                        value={newDisc.PlasticType}
                                        onChange={handleNewDiscChange}
                                      />

                                      {/* Category */}
                                      <TextField
                                        label="Category"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name="Category"
                                        value={newDisc.Category}
                                        onChange={handleNewDiscChange}
                                        placeholder="e.g., Fairway Driver"
                                      />

                                      {/* Brand Autocomplete with option to create a new brand */}
                                      {!isCreatingNewBrand ? (
                                        <Autocomplete
                                          options={brandsData}
                                          getOptionLabel={(option) =>
                                            option.BrandName
                                          }
                                          onChange={(event, value) => {
                                            if (value) {
                                              setNewDisc({
                                                ...newDisc,
                                                BrandID: value.BrandID,
                                              });
                                            }
                                          }}
                                          value={brandsData.find(
                                            (brand) =>
                                              brand.BrandID === newDisc.BrandID
                                          )}
                                          inputValue={typedBrandName}
                                          onInputChange={handleBrandInputChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Select Brand"
                                              variant="outlined"
                                              fullWidth
                                              margin="normal"
                                            />
                                          )}
                                          renderOption={(props, option) => (
                                            <li {...props} key={option.BrandID}>
                                              {option.BrandName}
                                            </li>
                                          )}
                                          noOptionsText={
                                            <Button
                                              onClick={handleCreateNewBrand}
                                              color="primary"
                                            >
                                              Create a New Brand
                                            </Button>
                                          }
                                        />
                                      ) : (
                                        <div style={{ marginTop: "20px" }}>
                                          <TextField
                                            label="New Brand Name"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={newBrand}
                                            onChange={handleNewBrandChange}
                                          />
                                          <Button
                                            onClick={handleSaveNewBrand}
                                            color="primary"
                                            style={{ marginTop: "10px" }}
                                          >
                                            Save New Brand
                                          </Button>
                                        </div>
                                      )}
                                    </DialogContent>

                                    <DialogActions>
                                      <Button
                                        onClick={handleCloseDialog}
                                        color="secondary"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={handleSaveNewDisc}
                                        color="primary"
                                      >
                                        Save Disc
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>

                                {/* <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Disc Name"
                                    defaultValue={disc.MoldName}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          MoldName: e.target.value, // Update the disc name with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Disc: </strong>
                                    {disc.MoldName}
                                  </p>
                                )}
                              </div> */}
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Color"
                                      defaultValue={disc.color}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // Ensure we're updating the editedDisc object, not the original disc
                                        if (editedDisc) {
                                          setEditedDisc({
                                            ...editedDisc, // Spread the current state of editedDisc
                                            color: e.target.value, // Update the color with the new value
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Color: </strong>
                                      {disc.color}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Bin"
                                      defaultValue={disc.bin}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // Ensure we're updating the editedDisc object, not the original disc
                                        if (editedDisc) {
                                          setEditedDisc({
                                            ...editedDisc, // Spread the current state of editedDisc
                                            bin: e.target.value, // Update the bin with the new value
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Bin: </strong>
                                      {disc.bin}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Date Found"
                                      defaultValue={disc.dateFound}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // Ensure we're updating the editedDisc object, not the original disc
                                        if (editedDisc) {
                                          setEditedDisc({
                                            ...editedDisc, // Spread the current state of editedDisc
                                            dateFound: e.target.value, // Update the dateFound with the new value
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Date Found: </strong>
                                      {disc.dateFound}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Date Texted"
                                      defaultValue={disc.dateTexted}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // Ensure we're updating the editedDisc object, not the original disc
                                        if (editedDisc) {
                                          setEditedDisc({
                                            ...editedDisc, // Spread the current state of editedDisc
                                            dateTexted: e.target.value, // Update the dateTexted with the new value
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Date Texted: </strong>
                                      {disc.dateTexted}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Date Claimed"
                                      defaultValue={disc.dateClaimed}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // Ensure we're updating the editedDisc object, not the original disc
                                        if (editedDisc) {
                                          setEditedDisc({
                                            ...editedDisc, // Spread the current state of editedDisc
                                            dateClaimed: e.target.value, // Update the dateClaimed with the new value
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Date Claimed: </strong>
                                      {disc.dateClaimed}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Status (read-only)"
                                      defaultValue={disc.status}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Status: </strong>
                                      {disc.status}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Claim By (read-only)"
                                      defaultValue={disc.claimBy}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Claim By: </strong>
                                      {disc.claimBy}
                                    </p>
                                  )}
                                </div>
                                <div className="row">
                                  {editedDiscID === disc.id ? (
                                    <TextField
                                      id="outlined-uncontrolled"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      label="Comments"
                                      defaultValue={disc.comments}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // Ensure we're updating the editedDisc object, not the original disc
                                        if (editedDisc) {
                                          setEditedDisc({
                                            ...editedDisc, // Spread the current state of editedDisc
                                            comments: e.target.value, // Update the comments with the new value
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <p className="detailed-text">
                                      <strong>Comments: </strong>
                                      {disc.comments}
                                    </p>
                                  )}
                                </div>
                                {isLoading ? (
                                  <div>
                                    <CircularProgress />
                                  </div>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {disc.id !== claimedDisc && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          width: "100%",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <button
                                          className="delete-button"
                                          onClick={() => handleDeleteClick()}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon
                                            sx={{
                                              fontSize: ".75rem",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <p>Delete Disc</p>
                                        </button>
                                        {/* Check if the Claim By is in the past */}
                                        {new Date(disc.claimBy!) <
                                          new Date() && (
                                          <button
                                            className="sold-button"
                                            onClick={() =>
                                              listForSale(
                                                disc.id!.toString(),
                                                course.courseName
                                              )
                                            }
                                          >
                                            <SellOutlinedIcon
                                              sx={{
                                                fontSize: ".75rem",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <p>List for Sale</p>
                                          </button>
                                        )}

                                        <button
                                          className="claimed-button"
                                          onClick={() =>
                                            markAsClaimed(disc.id!.toString())
                                          }
                                        >
                                          <BackHandOutlinedIcon
                                            sx={{
                                              fontSize: ".75rem",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <p>Disc Claimed</p>
                                        </button>
                                      </Box>
                                    )}
                                  </Box>
                                )}

                                {showDeletePopup && (
                                  <DeleteConfirmationPopup
                                    disc={disc}
                                    open={showDeletePopup}
                                    onClose={handleClose}
                                    onConfirm={() => handleConfirmDelete(disc)}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <BackToTopButton />
          </div>
          <Snackbar
            open={showSuccessMessage}
            autoHideDuration={4000}
            onClose={clearSuccessMessage}
          >
            <Alert
              onClose={clearSuccessMessage}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            open={showErrorMessage}
            autoHideDuration={4000}
            onClose={clearErrorMessage}
          >
            <Alert
              onClose={clearErrorMessage}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            open={showInformationalMessage}
            autoHideDuration={4000}
            onClose={clearInformationalMessage}
          >
            <Alert
              onClose={clearInformationalMessage}
              severity="info"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {informationalMessage}
            </Alert>
          </Snackbar>
        </div>
      </PullToRefresh>
    </Box>
  );
}

export default Inventory;
