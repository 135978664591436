import React, { useEffect, useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraRollOutlinedIcon from "@mui/icons-material/CameraRollOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import EditIcon from "@mui/icons-material/Edit";
import "../styles/EnterLostDisc.css";
import "../globals.css";
import { API_BASE_URL } from "../App";
import {
  Alert,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import CameraComponent from "./CameraComponent";
import ImageDetectionPopup from "./ImageDetectionPopup";
import { courseAndNavProps } from "./Inventory";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import wordsToNumbers from "words-to-numbers";
import colors from "color-name-list";

export interface DiscData {
  course: string;
  name: string;
  disc: DiscMold | null;
  phoneNumber: string;
  bin: string;
  comments: string;
  dateFound: string;
  color: string;
}

export interface Brand {
  BrandID: number | null;
  BrandName: string;
}

export interface DiscMold {
  MoldID: number | null;
  BrandID: number | null;
  Category: string;
  MoldName: string;
  PlasticType: string;
}

// interface VoiceSupportState {
//   isListening: boolean;
//   activeField: keyof DiscData | "";
// }

// Type for the match result
// interface MatchResult {
//   combination: string;
//   match: string;
//   score: number;
// }

// type FuzzballExtractResult = [string, number, number]; // [match, score, index]

// declare global {
//   interface Window {
//     SpeechRecognition: typeof SpeechRecognition;
//     webkitSpeechRecognition: typeof SpeechRecognition;
//   }

//   interface SpeechRecognition extends EventTarget {
//     new (): SpeechRecognition;
//     continuous: boolean;
//     interimResults: boolean;
//     onresult: (event: SpeechRecognitionEvent) => void;
//     start: () => void;
//     stop: () => void;
//     abort: () => void;
//     // Define other properties and methods as needed
//   }

//   interface SpeechRecognitionEvent extends Event {
//     results: SpeechRecognitionResultList;
//     // Define other properties as needed
//   }

//   interface SpeechRecognitionResultList {
//     readonly length: number;
//     item(index: number): SpeechRecognitionResult;
//     [index: number]: SpeechRecognitionResult;
//   }

//   interface SpeechRecognitionResult {
//     readonly isFinal: boolean;
//     [index: number]: SpeechRecognitionAlternative;
//   }

//   interface SpeechRecognitionAlternative {
//     readonly transcript: string;
//     // Define other properties as needed
//   }
// }

// interface CustomSpeechRecognitionEvent extends SpeechRecognitionEvent {
//   resultIndex: number;
// }

function EnterLostDisc(props: courseAndNavProps) {
  const {
    course,
    setSelectedIndex,
    createHandleClose,
    lastIndex,
    setLastIndex,
  } = props;

  const [discData, setDiscData] = useState<DiscData>({
    course: course.courseName,
    name: "",
    disc: null,
    phoneNumber: "",
    bin: "",
    comments: "",
    dateFound: new Date().toISOString().split("T")[0],
    color: "",
  });
  const [showDialog, setShowDialog] = useState<boolean>(false); // Dialog state
  const [newDisc, setNewDisc] = useState<DiscMold>({
    MoldID: null,
    BrandID: null,
    Category: "",
    MoldName: "",
    PlasticType: "",
  }); // To store new entry fields
  const [newBrand, setNewBrand] = useState<string>(""); // For creating a new brand
  const [isCreatingNewBrand, setIsCreatingNewBrand] = useState<boolean>(false); // New brand entry state
  const [typedDiscName, setTypedDiscName] = useState<string>("Volt"); // To capture the typed disc name
  const [typedBrandName, setTypedBrandName] = useState<string>(""); // To capture the typed brand name

  // const [voiceSupport, setVoiceSupport] = useState<VoiceSupportState>({
  //   isListening: false,
  //   activeField: "",
  // });

  // const fuzzball = require("fuzzball");

  const MAX_RETRY_ATTEMPTS = 3; // Maximum number of retry attempts
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const [showVoice, setShowVoice] = useState(false);
  // const [preventFocus, setPreventFocus] = useState(false);
  const [showManual, setShowManual] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [selection, setSelection] = useState("");
  const [side, setSide] = useState<string>("");
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [backImage, setBackImage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null); // ref to focus the input programmatically
  const [apiResponseData, setApiResponseData] = useState<
    Array<{ text: string; category: string }>
  >([]);
  // Define the predefined categories
  const predefinedCategories: string[] = [
    "Select Category",
    "Name",
    "Disc Brand",
    "Disc Name",
    "Phone Number",
    "Color",
    "Comments",
  ];

  const { getToken } = useKindeAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const clearSuccessMessage = () => {
    setShowSuccessMessage(false);
    setSuccessMessage("");
  };

  const clearErrorMessage = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
  };

  const [brandsData, setBrandsData] = useState<Brand[]>([]);
  const [dbDiscData, setDBDiscData] = useState<DiscMold[]>([]);
  // const [searchTerm, setSearchTerm] = useState<string>("");

  async function fetchBrandsAndDiscData() {
    try {
      const accessToken = await getToken();
      const response = await axios.get(
        `${API_BASE_URL}/discs/brands-and-names`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Brands and Disc Data:", response.data);
      setBrandsData(response.data.brands);
      setDBDiscData(response.data.discMolds);
    } catch (error) {
      console.error("Error fetching brands data:", error);
    }
  }

  useEffect(() => {
    fetchBrandsAndDiscData();
  }, []);

  const handleDiscChange = (event: any, value: any) => {
    const selectedDisc = dbDiscData.find(
      (disc) => disc.MoldID === value?.MoldID
    );
    if (selectedDisc) {
      setDiscData({ ...discData, disc: selectedDisc });
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setTypedDiscName(newInputValue); // Capture the typed disc name
  };

  const handleBrandInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setTypedBrandName(newInputValue); // Capture the typed brand name
  };

  const handleCreateNewEntry = () => {
    setNewDisc({ ...newDisc, MoldName: typedDiscName }); // Prefill the disc name with the typed input
    setShowDialog(true); // Show dialog for new entry
  };

  const handleCreateNewBrand = () => {
    setNewBrand(typedBrandName); // Prefill the brand name with the typed input
    setIsCreatingNewBrand(true); // Show the new brand creation form
  };

  const handleNewDiscChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewDisc({ ...newDisc, [event.target.name]: event.target.value });
  };

  const handleNewDiscCategoryChange = (event: SelectChangeEvent<string>) => {
    setNewDisc({ ...newDisc, Category: event.target.value as string });
  };

  const handleSaveNewDisc = async () => {
    try {
      const formData = new FormData();
      if (newDisc.BrandID !== null) {
        formData.append("BrandID", newDisc.BrandID.toString());
      }

      const newDiscWithCapitalizedWords = newDisc.MoldName.trim().replace(
        /\b\w/g,
        (c) => c.toUpperCase()
      );

      const newCategoryWithCapitalizedWords = newDisc.Category.trim().replace(
        /\b\w/g,
        (c) => c.toUpperCase()
      );

      const newPlasticTypeWithCapitalizedWords =
        newDisc.PlasticType.trim().replace(/\b\w/g, (c) => c.toUpperCase());

      formData.append("Category", newCategoryWithCapitalizedWords);
      formData.append("MoldName", newDiscWithCapitalizedWords);
      formData.append("PlasticType", newPlasticTypeWithCapitalizedWords);

      // Send the form data using axios
      const response = await axios.post(`${API_BASE_URL}/disc`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("New Disc Created:", response.data);
      // Save the new disc entry and close the dialog
      setShowDialog(false); // Close the dialog after saving
      const newDiscTmp = {
        MoldID: response.data.MoldID,
        BrandID: response.data.BrandID,
        Category: response.data.Category,
        MoldName: response.data.MoldName,
        PlasticType: response.data.PlasticType,
      };
      setNewDisc(newDiscTmp);
      setDBDiscData((prevDiscs) => [...prevDiscs, newDiscTmp]); // Add the new disc to the existing list
      setIsCreatingNewBrand(false); // Reset the new brand state
      setDiscData({ ...discData, disc: newDiscTmp }); // Update the disc data with the new disc
      setSuccessMessage("New disc created successfully");
      setShowSuccessMessage(true);
    } catch (error) {
      console.error("Error creating new disc:", error);
      setErrorMessage("Error creating new disc");
      setShowErrorMessage(true);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false); // Close dialog without saving
    setIsCreatingNewBrand(false); // Reset the new brand state
  };

  const handleNewBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewBrand(event.target.value); // Update the new brand name
  };

  const handleSaveNewBrand = async () => {
    try {
      const formData = new FormData();
      const newBrandWithCapitalizedWords = newBrand
        .trim()
        .replace(/\b\w/g, (c) => c.toUpperCase());
      formData.append("BrandName", newBrandWithCapitalizedWords);

      // Send the form data using axios to create the brand
      const response = await axios.post(`${API_BASE_URL}/brand`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("New Brand Created:", response.data);

      // Create a new brand entry object
      const newBrandEntry = {
        BrandID: response.data.BrandID,
        BrandName: response.data.BrandName,
      };

      // Add the new brand to the existing list of brands
      setBrandsData((prevBrands) => [...prevBrands, newBrandEntry]);

      // Automatically select the newly created brand for the disc being created
      setNewDisc({ ...newDisc, BrandID: newBrandEntry.BrandID });

      // Hide the "Create New Brand" form after the brand is created
      setIsCreatingNewBrand(false);
      setSuccessMessage("New brand created successfully");
      setShowSuccessMessage(true);
    } catch (error) {
      console.error("Error creating new brand:", error);
      setErrorMessage("Error creating new brand");
      setShowErrorMessage(true);
    }
  };

  // Define a function to map the incoming category to the predefined categories
  function mapToPredefinedCategory(category: string): string {
    if (predefinedCategories.includes(category)) {
      return category; // Matched a predefined category
    } else {
      return ""; // Default to an empty string
    }
  }

  const handlePlaceholderClick = (side: string) => {
    setSelection("camera");
    if (side === "front") setSide("front");
    else setSide("back");
    setShowCamera(true);
    // setShowVoice(false);
    setShowManual(false);
  };

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "phoneNumber") {
      // Remove all non-digit characters except the leading '+'
      let digits = value.replace(/\D/g, "");

      // Ensure the number starts with '1' for US numbers
      if (!digits.startsWith("1")) {
        digits = "1" + digits;
      }

      // Limit to 11 digits (including the leading '1')
      digits = digits.slice(0, 11);

      // Format the digits into E.164 format with +1 prefix
      formattedValue = `+${digits}`;
    }

    // Update the discData state
    setDiscData({ ...discData, [name]: formattedValue });
  };

  const handleUpdateCategory = (index: number, category: string) => {
    const updatedData = [...apiResponseData];
    updatedData[index].category = category;
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleUpdateText = (index: number, text: string) => {
    const updatedData = [...apiResponseData];
    updatedData[index].text = text;
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleDeleteRow = (index: number) => {
    const updatedData = [...apiResponseData];
    updatedData.splice(index, 1);
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  // Combine disc name and brand for Autocomplete
  const formattedDiscs = dbDiscData.map((disc) => {
    const brand = brandsData.find((b) => b.BrandID === disc.BrandID);
    return {
      label: `${brand?.BrandName || "Unknown Brand"} - ${disc.MoldName} (${disc.PlasticType} Plastic)`,
      MoldID: disc.MoldID,
    };
  });

  const handleImageCapture = async (
    imageData: string,
    side: string,
    retryCount = 0
  ) => {
    setIsLoading(true); // Set loading to true when the request is initiated
    clearErrorMessage(); // Clear error message
    clearSuccessMessage(); // Clear success message
    console.log(`Captured ${side} image:`, imageData);
    if (side === "front") {
      setFrontImage(imageData);
      setSide("back");
    } else if (side === "back") {
      setBackImage(imageData);
      setSide("front");
    }

    try {
      const accessToken = await getToken();

      const response = await axios.post(`${API_BASE_URL}/detect-text`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        imageBase64: imageData,
      });
      const data = response.data;
      console.log("Data:", data);

      if (!data || data.length === 0) {
        // Handle no text detected
        console.log("No text detected.");
        setErrorMessage("No text detected.");
        setShowErrorMessage(true);
        setIsLoading(false);
        return;
      }

      // Map the categories
      const mappedResponse = data.map(
        (item: { text: any; category: string }) => ({
          text: item.text,
          category: mapToPredefinedCategory(item.category),
        })
      );

      console.log("Mapped response:", mappedResponse);

      setApiResponseData(mappedResponse);
      setIsLoading(false);
      setShowPopup(true); // Show popup when data is received
    } catch (error) {
      console.error("Error processing image:", error);

      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        // Check if the error status is 500 and retry if within the maximum retry attempts
        if (
          axiosError.response?.status === 500 &&
          retryCount < MAX_RETRY_ATTEMPTS
        ) {
          console.log(`Retrying API call (Attempt ${retryCount + 1})...`);
          await new Promise<void>((resolve) => setTimeout(resolve, 1000)); // Wait for a moment before retrying
          await handleImageCapture(imageData, side, retryCount + 1);
        } else if (axiosError.response?.status === 204) {
          // Handle no text detected
          console.log("No text detected.");
          setErrorMessage("No text detected.");
          setShowErrorMessage(true);
          setIsLoading(false);
        } else {
          // Handle other errors or reach maximum retry attempts
          console.error("Max retry attempts reached or non-retryable error.");
          setErrorMessage("Error processing image.");
          setShowErrorMessage(true);
          setIsLoading(false);
        }
      }
    }
    setIsLoading(false);
  };

  const prefillForm = () => {
    console.log("Prefilling form with data:", apiResponseData);
    // Iterate through the APIResponseData and map categories to input fields
    apiResponseData.forEach((item) => {
      console.log("Item:", item);
      switch (item.category) {
        case "Disc ID":
          setDiscData((prevData) => ({
            ...prevData,
            disc:
              dbDiscData.find((disc) => disc.MoldID === parseInt(item.text)) ||
              null,
          }));
          break;
        case "Comments":
          setDiscData((prevData) => ({
            ...prevData,
            comments: item.text,
          }));
          break;
        case "Phone Number":
          setDiscData((prevData) => ({
            ...prevData,
            phoneNumber: item.text,
          }));
          break;
        case "Name":
          setDiscData((prevData) => ({
            ...prevData,
            name: item.text,
          }));
          break;
        case "Color":
          setDiscData((prevData) => ({
            ...prevData,
            color: item.text,
          }));
          break;
        case "Disc Brand":
          setDiscData((prevData) => ({
            ...prevData,
            disc:
              dbDiscData.find(
                (disc) =>
                  disc.MoldName.toLowerCase() === item.text.toLowerCase()
              ) || null,
          }));
          break;
        case "Disc Name":
          setDiscData((prevData) => ({
            ...prevData,
            disc:
              dbDiscData.find(
                (disc) =>
                  disc.MoldName.toLowerCase() === item.text.toLowerCase()
              ) || null,
          }));
          break;
        default:
          // If there is no match, do nothing
          break;
      }
    });

    // Close the popup after prefilling the form
    setShowPopup(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    clearErrorMessage(); // Clear error message
    clearSuccessMessage(); // Clear success message
    setIsLoading(true); // Set loading to true when the request is initiated

    const formData = new FormData();
    Object.entries(discData).forEach(([key, value]) => {
      if (key !== "disc") {
        formData.append(key, value);
      }
    });

    // Only send the MoldID (discID)
    if (discData?.disc?.MoldID) {
      formData.append("discId", discData.disc.MoldID.toString()); // Append discID (MoldID)
    } else {
      setErrorMessage("No disc selected");
      setIsLoading(false);
      return;
    }

    if (frontImage) formData.append("frontImage", frontImage);
    if (backImage) formData.append("backImage", backImage);

    const accessToken = await getToken();
    axios
      .post(`${API_BASE_URL}/found-discs`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Disc added:", response.data);

        // Set success message with the ID of the row from the DB
        setSuccessMessage(`Disc added with ID ${response.data.id}`);
        setShowSuccessMessage(true);

        // Clear the form and loading state
        setDiscData({
          course: course.courseName,
          name: "",
          disc: {
            BrandID: null,
            MoldID: null,
            MoldName: "",
            PlasticType: "",
            Category: "",
          },
          phoneNumber: "",
          bin: discData.bin, //don't overwrite the bin number
          comments: "",
          dateFound: new Date().toISOString().split("T")[0],
          color: "",
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error adding disc:", error);
        setIsLoading(false); // Clear loading state on error
        setErrorMessage("Error adding disc. Please try again.");
        setShowErrorMessage(true);
      });
  };

  const handleCameraButtonClick = () => {
    setSelection("camera");
    setShowCamera(true);
    // setShowVoice(false);
    setShowManual(false);
  };

  const handleManualButtonClick = () => {
    setSelection("manual");
    setShowManual(true);
    // setShowVoice(false);
    setShowCamera(false);
  };

  const resetForm = (event: React.FormEvent) => {
    event.preventDefault();
    setDiscData({
      course: course.courseName,
      name: "",
      disc: {
        BrandID: null,
        MoldID: null,
        MoldName: "",
        PlasticType: "",
        Category: "",
      },
      phoneNumber: "",
      bin: "",
      comments: "",
      dateFound: new Date().toISOString().split("T")[0],
      color: "",
    });
    setFrontImage(null);
    setBackImage(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const form = event.currentTarget.form as HTMLFormElement;
      const index = Array.prototype.indexOf.call(form, event.currentTarget);
      if (index < form.elements.length - 1) {
        const nextElement = form.elements[index + 1] as HTMLInputElement;
        nextElement.focus();
        event.preventDefault();
      }
    }
  };

  const [lastIndexPageName, setLastIndexPageName] = useState<string>("");

  useEffect(() => {
    if (lastIndex === 0) {
      setLastIndexPageName("Enter Lost Disc");
    } else if (lastIndex === 1) {
      setLastIndexPageName("Inventory");
    } else if (lastIndex === 2) {
      setLastIndexPageName("For Sale");
    } else {
      setLastIndexPageName("Error");
    }
  }, [lastIndex]);

  const nameRef = useRef<HTMLInputElement | null>(null);
  const phoneNumberRef = useRef<HTMLInputElement | null>(null);
  const brandRef = useRef<HTMLInputElement | null>(null);
  const discRef = useRef<HTMLInputElement | null>(null);
  const colorRef = useRef<HTMLInputElement | null>(null);
  const binRef = useRef<HTMLInputElement | null>(null);
  const commentsRef = useRef<HTMLInputElement | null>(null);

  // useEffect(() => {
  //   switch (voiceSupport.activeField) {
  //     case "color":
  //       if (colorRef.current) {
  //         colorRef.current.focus();
  //       }
  //       break;
  //     case "phoneNumber":
  //       if (phoneNumberRef.current) {
  //         phoneNumberRef.current.focus();
  //       }
  //       break;
  //     case "brand":
  //       if (brandRef.current) {
  //         brandRef.current.focus();
  //       }
  //       break;
  //     case "disc":
  //       if (discRef.current) {
  //         discRef.current.focus();
  //       }
  //       break;
  //     case "bin":
  //       if (binRef.current) {
  //         binRef.current.focus();
  //       }
  //       break;
  //     case "comments":
  //       if (commentsRef.current) {
  //         commentsRef.current.focus();
  //       }
  //       break;
  //     case "name":
  //       if (nameRef.current) {
  //         nameRef.current.focus();
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }, [voiceSupport.activeField]);

  return (
    <div className="lost-disc-container">
      {lastIndex !== -1 && (
        <div className="row-back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="back-icon"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
          </svg>

          <p
            className="lastIndexPageName"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            {lastIndexPageName}
          </p>
        </div>
      )}
      <h1 className="header">{course.courseName} L & F</h1>
      <h1 className="EnterLostDisc">ENTER LOST DISC</h1>
      <div className="button-container">
        <button
          className={
            showCamera ? "button-options-red active" : "button-options-red"
          }
          onClick={handleCameraButtonClick}
        >
          <CameraRollOutlinedIcon className="button-icon-camera" />
          <span className="button-text">
            {isMobile ? "Camera" : "Use Camera"}
          </span>
        </button>
        {/* <button
          className={
            showVoice ? "button-options-blue active" : "button-options-blue"
          }
          onClick={handleUseVoiceClick}
        >
          <MicNoneOutlinedIcon className="button-icon-camera" />
          <span className="button-text">
            {isMobile ? "Voice" : "Use Voice"}
          </span>
        </button> */}

        <button
          className={
            showManual ? "button-options-black active" : "button-options-black"
          }
          onClick={handleManualButtonClick}
        >
          <CreateOutlinedIcon className="button-icon-pencil" />
          <span className="button-text">
            {isMobile ? "Manual" : "Manual Entry"}
          </span>
        </button>
      </div>

      <div className="use-camera-container">
        {showCamera && (
          <CameraComponent
            onCapture={(imageData, side) => handleImageCapture(imageData, side)}
            side={side}
            setSide={setSide}
            switchToManual={handleManualButtonClick}
            isLoading={isLoading}
          />
        )}

        {selection === "manual" || selection === "voice" ? (
          <form
            onSubmit={handleSubmit}
            style={{ width: "75%", margin: "auto", marginTop: "20px" }}
          >
            <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="text"
                id="name"
                name="name"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "name" ? "activeField" : ""
                // }`}
                label="Name"
                value={discData.name}
                onChange={handleChange}
                inputRef={nameRef}
                onKeyDown={handleKeyDown}
              />
              {/* {voiceSupport.activeField === "name" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )} */}
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              {/* <TextField
                type="tel" // Use type="tel" to display the numeric keyboard on mobile
                id="phoneNumber"
                name="phoneNumber"
                value={discData.phoneNumber}
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "phoneNumber"
                //     ? "activeField"
                //     : ""
                // }`}
                label="Phone Number"
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                inputRef={phoneNumberRef}
              /> */}
              {/* <TextField
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={discData.phoneNumber}
                className="input-field"
                label="Phone Number"
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                inputRef={phoneNumberRef}
                autoComplete="tel"
                inputProps={{
                  pattern: "\\d*",
                  title: "Phone number should contain only digits",
                }}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                onKeyDown={handleKeyDown}
              /> */}
              <TextField
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={discData.phoneNumber}
                className="input-field"
                label="Phone Number"
                onChange={handleChange}
                placeholder="+1XXXXXXXXXX"
                inputRef={phoneNumberRef}
                autoComplete="tel"
                inputProps={{
                  pattern: "\\+1\\d{10}",
                  title: "Phone number should be in the format +1XXXXXXXXXX",
                }}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                onKeyDown={handleKeyDown}
              />
              {/* {voiceSupport.activeField === "phoneNumber" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )} */}
            </div>
            {/* <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="text"
                id="brand"
                name="brand"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "brand" ? "activeField" : ""
                // }`}
                label="Brand"
                value={discData.brand}
                onChange={handleChange}
                inputRef={brandRef}
                onKeyDown={handleKeyDown}
              />
              {voiceSupport.activeField === "brand" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div> */}
            {/* <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="text"
                id="disc"
                name="disc"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "disc" ? "activeField" : ""
                // }`}
                label="Disc"
                value={discData.
                onChange={handleChange}
                inputRef={discRef}
                onKeyDown={handleKeyDown}
              />
               {voiceSupport.activeField === "disc" &&                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )}
            </div> */}
            {/* <div className="form-group" style={{ position: "relative" }}>
              <Select
                labelId="disc-label"
                id="disc-select"
                value={discData.disc?.MoldID || ""}
                onChange={() => handleDiscChange} // Make sure this is a function reference, not an inline arrow function
                displayEmpty
                className="input-field"
                style={{ border: "none", textAlign: "left", opacity: 0.8 }}
              >
                <MenuItem value="" disabled>
                  Select a Disc
                </MenuItem>
                {brandsData.map((brand) => (
                  <React.Fragment key={brand.BrandID}>
                    <ListSubheader>{brand.BrandName}</ListSubheader>
                    {groupedDiscs[brand.BrandID!] &&
                    groupedDiscs[brand.BrandID!].length > 0 ? (
                      groupedDiscs[brand.BrandID!].map((disc: DiscMold) => (
                        <MenuItem key={disc.MoldID!} value={disc.MoldID!}>
                          {disc.MoldName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No discs found</MenuItem>
                    )}
                  </React.Fragment>
                ))}
              </Select>
            </div> */}
            <div
              className="form-group"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Autocomplete
                options={[
                  ...formattedDiscs,
                  {
                    label: `Create "${typedDiscName}" as a New Entry`,
                    MoldID: null,
                  },
                ]} // Dynamically add the "Create New Entry" option
                getOptionLabel={(option) => option.label}
                className="input-field"
                style={{
                  border: "none",
                  textAlign: "left",
                  backgroundColor: "var(--primary-gray)",
                }}
                value={
                  dbDiscData
                    .map((disc) => ({
                      label: `${brandsData.find((b) => b.BrandID === disc.BrandID)?.BrandName || "Unknown Brand"} - ${disc.MoldName}`,
                      MoldID: disc.MoldID,
                    }))
                    .find((disc) => disc.MoldID === discData?.disc?.MoldID) ||
                  null
                }
                onChange={(event, newValue) => {
                  if (newValue?.MoldID === null) {
                    handleCreateNewEntry(); // Call your create function when the "Create a New Entry" option is selected
                  } else {
                    handleDiscChange(event, newValue);
                  }
                }}
                inputValue={typedDiscName}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Disc"
                    variant="outlined"
                    helperText="Type to search or create a new entry"
                    inputRef={inputRef} // set the ref to programmatically focus the input
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        backgroundColor: "var(--primary-white)", // Change the background color of the input field
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { index }) => (
                  <>
                    <li
                      {...props}
                      key={option.MoldID || "new-entry"}
                      style={{
                        padding: "10px",
                        backgroundColor:
                          index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternate background colors
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        textAlign: "left",
                        fontWeight: option.MoldID === null ? "bold" : "normal", // Bold for "Create a New Entry"
                        color: option.MoldID === null ? "#1976d2" : "#000",
                      }}
                    >
                      {option.label}
                    </li>
                    {index < formattedDiscs.length && <Divider />}{" "}
                    {/* Add Divider between items, but not after the last item */}
                  </>
                )}
                fullWidth
                disablePortal
                noOptionsText={
                  <div>
                    <button onClick={handleCreateNewEntry} color="primary">
                      Create a New Entry
                    </button>
                  </div>
                }
              />

              {/* Dialog for creating a new disc entry */}
              {/* Dialog for creating a new disc entry */}
              <Dialog open={showDialog} onClose={handleCloseDialog}>
                <DialogTitle>Create a New Disc Entry</DialogTitle>
                <DialogContent>
                  {/* New Disc Name */}
                  <TextField
                    label="Disc Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="MoldName"
                    value={newDisc.MoldName}
                    onChange={handleNewDiscChange}
                  />

                  {/* Plastic Type */}
                  <TextField
                    label="Plastic Type"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="PlasticType"
                    value={newDisc.PlasticType}
                    onChange={handleNewDiscChange}
                  />

                  {/* Category */}
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: 16 }}
                  >
                    <InputLabel id="discType-label">Disc Type</InputLabel>
                    <Select
                      labelId="Category"
                      id="Category"
                      value={newDisc.Category}
                      onChange={handleNewDiscCategoryChange}
                      label="Disc Category"
                      placeholder="e.g. Distance Driver"
                    >
                      <MenuItem value="putter">Putter</MenuItem>
                      <MenuItem value="midrange">Midrange</MenuItem>
                      <MenuItem value="fairwayDriver">Fairway Driver</MenuItem>
                      <MenuItem value="distanceDriver">
                        Distance Driver
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {/* Brand Autocomplete with option to create a new brand */}
                  {!isCreatingNewBrand ? (
                    <Autocomplete
                      options={brandsData}
                      getOptionLabel={(option) => option.BrandName}
                      onChange={(event, value) => {
                        if (value) {
                          setNewDisc({ ...newDisc, BrandID: value.BrandID });
                        }
                      }}
                      value={brandsData.find(
                        (brand) => brand.BrandID === newDisc.BrandID
                      )}
                      inputValue={typedBrandName}
                      onInputChange={handleBrandInputChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Brand"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.BrandID}>
                          {option.BrandName}
                        </li>
                      )}
                      noOptionsText={
                        <Button onClick={handleCreateNewBrand} color="primary">
                          Create a New Brand
                        </Button>
                      }
                    />
                  ) : (
                    <div style={{ marginTop: "20px" }}>
                      <TextField
                        label="New Brand Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={newBrand}
                        onChange={handleNewBrandChange}
                      />
                      <Button
                        onClick={handleSaveNewBrand}
                        color="primary"
                        style={{ marginTop: "10px" }}
                      >
                        Save New Brand
                      </Button>
                    </div>
                  )}
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleCloseDialog} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={handleSaveNewDisc} color="primary">
                    Save Disc
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="text"
                id="color"
                name="color"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "color" ? "activeField" : ""
                // }`}
                label="Color"
                value={discData.color}
                onChange={handleChange}
                inputRef={colorRef}
                onKeyDown={handleKeyDown}
              />
              {/* {voiceSupport.activeField === "color" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )} */}
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <TextField
                type="number" // Use type="number" to display the numeric keyboard on mobile
                id="bin"
                name="bin"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "bin" ? "activeField" : ""
                // }`}
                label="Bin"
                value={discData.bin}
                onChange={handleChange}
                inputRef={binRef}
                onKeyDown={handleKeyDown}
              />
              {/* {voiceSupport.activeField === "bin" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )} */}
            </div>
            <div
              className="form-group custom-comments"
              style={{ position: "relative" }}
            >
              <TextField
                type="text" // Use type="number" to display the numeric keyboard on mobile
                id="comments"
                name="comments"
                className="input-field"
                // className={`input-field ${
                //   voiceSupport.activeField === "comments" ? "activeField" : ""
                // }`}
                label="Comments"
                value={discData.comments}
                onChange={handleChange}
                inputRef={commentsRef}
                onKeyDown={handleKeyDown}
              />
              {/* {voiceSupport.activeField === "comments" &&
                voiceSupport.isListening && (
                  <MicNoneOutlinedIcon className="listeningIcon" />
                )} */}
            </div>
            <div className="form-buttons-container">
              <button className="reset-format" onClick={resetForm}>
                Reset Form
              </button>
              <button
                type="submit"
                className={`submit-button ${isLoading ? "loading" : ""}`}
              >
                {isLoading ? <CircularProgress /> : "Submit Disc"}
              </button>
            </div>
          </form>
        ) : (
          <></>
        )}

        {/* {selection === "voice" && (
          <div className="form-navigation-buttons">
            <button
              type="button"
              onClick={moveBack}
              className="back-button"
              disabled={voiceSupport.activeField === "name"}
            >
              Back
            </button>
            <button
              type="button"
              onClick={moveToNextField}
              className="next-button"
              disabled={voiceSupport.activeField === "comments"}
            >
              Next
            </button>
            <button
              type="button"
              onClick={handleEndVoice}
              className="end-button"
            >
              End Voice Session
            </button>
            <Checkbox
              checked={useBulk}
              onChange={(e) => setUseBulk(e.target.checked)}
              name="useBulkOption"
              color="primary"
              style={{ margin: "0px", padding: "0px" }}
            />
            <label htmlFor="useBulkOption" className="bulk-entry-label">
              Use Bulk Entry
            </label>
          </div>
        )} */}

        {selection && (
          <div className="image-placeholder-container">
            {/* Front Image */}
            <div
              className="image-container"
              onClick={() => handlePlaceholderClick("front")}
            >
              {frontImage ? (
                <img src={frontImage} alt="Front" />
              ) : (
                <div className="image-placeholder">
                  <CameraAltIcon className="camera-icon" />
                  {/* <span>Capture</span> */}
                </div>
              )}
            </div>
            <div className="image-placeholder-label">Front</div>

            {/* Back Image */}
            <div
              className="image-container"
              onClick={() => handlePlaceholderClick("back")}
            >
              {backImage ? (
                <img src={backImage} alt="Back" />
              ) : (
                <div className="image-placeholder">
                  <CameraAltIcon className="camera-icon" />
                  {/* <span>Capture Back</span> */}
                </div>
              )}
            </div>
            <div className="image-placeholder-label">Back</div>
          </div>
        )}
      </div>

      {showPopup && (
        <ImageDetectionPopup
          data={apiResponseData}
          onClose={() => setShowPopup(false)}
          onUpdateCategory={handleUpdateCategory}
          onUpdateText={handleUpdateText}
          prefillForm={prefillForm}
          onDelete={handleDeleteRow}
          categories={predefinedCategories}
          setTypedDiscName={setTypedDiscName}
        />
      )}

      {/* {selection === "camera" && (
        <Typography
          sx={{
            color: "black",
            fontSize: "1.5rem",
            marginBottom: "10px",
          }}
        >
          Use Camera will be available soon. Please check back later.
        </Typography>
      )} */}

      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={4000}
        onClose={clearSuccessMessage}
      >
        <Alert
          onClose={clearSuccessMessage}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorMessage}
        autoHideDuration={4000}
        onClose={clearErrorMessage}
      >
        <Alert
          onClose={clearErrorMessage}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {showCamera && (
        <p className="disclaimer-circle ">
          The circle on the screen is an indicator of where the disc should be
          placed but the system will read any text in the frame. To avoid any
          additional text being added please place the disc on a flat surface.
        </p>
      )}
    </div>
  );
}

export default EnterLostDisc;
